import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import { notif } from '@/store/stateless/store';
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class ManualOrderService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ManualOrderService.vueInstance = app;
  }


  public static getManualOrderList(payload, index = 1) {
    console.log('index',payload)
    payload.index = index;
   
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_MANUAL_ORDER_LIST, payload)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static getLeadsByCustomer(payload) {
    return new Promise<void>((resolve, reject) => {
    store
        .dispatch(Actions.GET_LEAD_BY_CUSTOMER,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })

  }

  public static addTempManualOrder(data) {
    return new Promise<void>((resolve, reject) => {
      store.dispatch(Actions.ADD_TEMP_MANUAL_ORDER, data)
        .then((data) => {
        
          // Swal.fire({
          //   text: "Manual orders added successfully !",
          //   icon: "success",
          //   buttonsStyling: false,
          //   showConfirmButton: false,
          
          //   showCloseButton: true,
          //   showOkButton: false,
            
          // }).then(() => {
          // });
          resolve(data.data);
          notif.simple('Manual Order', 'success', 'Temp Manual Order saved successfully');

        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  public static async addManualOrder(data) {
    return await store.dispatch(Actions.ADD_MANUAL_ORDER, data)
    // return new Promise<void>((resolve, reject) => {
    //   store.dispatch(Actions.ADD_MANUAL_ORDER, data)
    //     .then((data) => {
    //       // Swal.fire({
    //       //   text: "Manual orders added successfully !",
    //       //   icon: "success",
    //       //   buttonsStyling: false,
    //       //   showConfirmButton: false,
    //       //   showCloseButton: true,
    //       //   showOkButton: false,
            
    //       // }).then(() => {
    //       // });
    //       //router.push({ name: "policy-approval", query: {type: 'manual-order'} });
    //       resolve(data);
    //       //notif.simple('Manual Order', 'success', 'Manual Order saved successfully');
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     })
    // })
  }

  public static showManualOrder(payload) {
    return new Promise<void>((resolve, reject) => {
    store
        .dispatch(Actions.FETCH_MANUAL_ORDER, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })

  }


  public static updatePromotionCode(data) {
    store.dispatch(Actions.UPDATE_PROMOTION_CODE, data)
      .then((data) => {
        // 
        // Swal.fire({
        //   text: "Promotion code has been updated successfully !",
        //   icon: "success",
        //   buttonsStyling: false,
        //   showConfirmButton: false,
         
        //   showCloseButton: true,
        //   showOkButton: false,

        // }).then(() => {
        // });
        router.push({ name: "promotion-code-list" });
        notif.simple('Promotion code', 'success', 'Promotion code has been updated successfully !');

      })
      .catch((error) => {
        // 
      })
  }

  public static deleteManualOrder(payload) {
    return new Promise<void>((resolve, reject) => {
    store
        .dispatch(Actions.DELETE_MANUAL_ORDER, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })

  }


}
export default ManualOrderService;